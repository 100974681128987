import React from "react";
import plugins from '../../content/pluginsInfo'
import fastentry from '../images/fastentry.jpg'
import fastentryto from '../images/fastentryto.jpg'
import Layout from "../components/layout"
export default class FastEntry extends React.Component{
    componentWillMount() {
        // window.scrollTo(0,0)
    }

    render() {
        const plugin=plugins[1]
        const styles=plugin.styleImgs.map((style,index)=><li className="animate-up" key={index}>
            <div> <img className="border" src={style}  alt="示意图" /></div>
        </li>)
        const demos=plugin.useInDemos.map((demo,index)=>
            <li className="animate-up" key={index}>
                <a href={demo.path}> <img className="border"  src={demo.poster}  alt="示意图" />
                    <div>{demo.name}</div></a>

            </li>

        )
        return(
          <Layout>
            <div>
                <section className="section yellow cutoff">
                    <div className="pagewidth">
                        <h1>快速入口</h1>
                        <h2>可根据需求，自由配置显示内容和跳转页面，方便用户快速使用查询、报名等功能。</h2>
                    </div>
                </section>

                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>功能选择</h1>
                        <h2>
                            <ul className="grid3 animated">
                                <li>快速报名</li>
                                <li>报名查询</li>
                                <li>领物通知</li>
                                <li>参赛号查询</li>
                                <li>成绩查询</li>
                            </ul>
                        </h2>
                    </div>
                </section>
                <section className="section white cutoff">
                    <div className="pagewidth">
                        <h1>示例</h1>

                        <ul className="grid2  animated">
                            <li >
                                <strong>点击报名→</strong>
                                <div><img className="border" src={fastentry}  alt="plugin" /></div>
                            </li>
                            <li >
                                <strong>快速跳转到报名页面</strong>
                                <div><img className="border" src={fastentryto} alt="plugin" /></div>
                            </li>
                        </ul>
                        {/*<img src="https://themify.me/wp-content/uploads/2016/03/simple-responsive.jpg"/>*/}
                    </div>
                </section>


                <section className="section aqua-green cutoff">
                    <div className="pagewidth">
                        <h1>多种样式</h1>
                        <ul className="grid3 slide-up animated">
                            {styles}
                        </ul>
                    </div>
                </section>

                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>适用模板</h1>
                        <ul className="grid3 slide-up animated">
                            {demos}
                        </ul>
                    </div>
                </section>
            </div>
          </Layout>
        )
    }
}